import React, { useState, useEffect } from "react";
import { Link, RouteComponentProps, Redirect } from "react-router-dom";
import useAuth from "contexts/common/auth-context";
import { Row, Col, Form, Alert, Input, Typography, Modal, message, Avatar } from "antd";
import Box from "containers/common/box";
import { MailOutlined, LockOutlined, GoogleOutlined } from "@ant-design/icons";
import Loading from "components/common/loading";
import { GetInvitationStreamDetails, StreamDetails } from "services/member/functions/invitations";
import { GetImage } from "services/common/images";
import * as queryString from "query-string";
import { ImageSize } from "models/common/image";
import FullscreenPage from "containers/common/fullscreen-page";
import environment from "environment";
import Button from "components/common/core/button";

const { Title, Paragraph } = Typography;

interface IRouteParams {
  r?: string;
}
const SignIn: React.FC<RouteComponentProps<IRouteParams>> = ({ location }) => {
  const auth = useAuth();

  const parseInvitationId = (redirectUrl: string | undefined): string | null => {
    if (redirectUrl) {
      if (redirectUrl.startsWith("/account/join")) {
        return (queryString.parse(redirectUrl.substring(redirectUrl.indexOf("?"))).i as string) || null;
      }
    }
    return null;
  };

  const redirect = queryString.parse(location.search).r as string;
  const invitationId = parseInvitationId(redirect);

  if (auth.isAuthenticated) {
    return <Redirect to={redirect ? decodeURIComponent(redirect) : "/"} />;
  }

  return (
    <FullscreenPage>
      <div style={{ padding: "1rem", width: "100%" }}>
        <div style={{ maxWidth: "300px", margin: "auto" }}>
          <img src="/images/logo-white.svg" alt="makeripples logo" style={{ width: "100%", padding: "1rem 2rem" }} />
        </div>
        <Row gutter={[12, 12]} justify="center">
          {invitationId && (
            <Col flex="0 1 300px">
              <Box>
                <Invitation invitationId={invitationId} />
              </Box>
            </Col>
          )}
          <Col flex="0 1 300px">
            <Box>
              <Title level={4} style={{ textAlign: "center" }}>
                Welcome back
              </Title>
              <SignInForm continueUrl={redirect} />
              <p style={{ marginTop: "1rem", textAlign: "center" }}>
                Don't have an account? <Link to={"/account/signup" + (redirect ? `?r=${redirect}` : "")}>Sign up</Link>
              </p>
            </Box>
          </Col>
        </Row>
      </div>
    </FullscreenPage>
  );
};

export default SignIn;

interface ISignInFormProps {
  continueUrl?: string;
}

const SignInForm: React.FC<ISignInFormProps> = ({ continueUrl }) => {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [signInError, setSignInError] = useState(null);
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await auth.actions.signIn(values.email, values.password);
    } catch (error: any) {
      setSignInError(error.message);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Form form={form} layout="vertical" onFinish={onFinish} hideRequiredMark>
        {signInError && (
          <Form.Item>
            <Alert message={signInError} type="error" />
          </Form.Item>
        )}
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Please provide a valid email" },
            { type: "email", message: "Please provide a valid email" },
          ]}
        >
          <Input prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />} />
        </Form.Item>
        <Form.Item name="password" label="Password" rules={[{ required: true, message: "Please input your password" }]}>
          <Input.Password prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />} />
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button style={{ width: "100%", fontWeight: "bold" }} loading={loading} type="primary" isSubmit>
            Sign in
          </Button>
          <br />
          <Button
            type="link"
            onClick={() => {
              setShowForgotPassword(true);
            }}
            style={{ marginTop: "0.5rem" }}
          >
            I forgot my password
          </Button>
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          <Button icon={<GoogleOutlined />} onClick={auth.actions.signInWithGoogle}>
            Sign in with Google
          </Button>
        </div>
      </Form>
      {showForgotPassword && (
        <ResetPasswordModal continueUrl={continueUrl} initialEmail={form.getFieldValue("email")} hideModal={() => setShowForgotPassword(false)} />
      )}
    </React.Fragment>
  );
};

interface IResetPasswordModalProps {
  initialEmail: string;
  hideModal: () => void;
  continueUrl?: string;
}

const ResetPasswordModal: React.FC<IResetPasswordModalProps> = ({ initialEmail, hideModal, continueUrl }) => {
  const auth = useAuth();

  const onFinish = (values: any) => {
    try {
      auth.actions.resetPassword(values.email, environment.baseUrl + (continueUrl || ""));
      hideModal();
      message.success("Sending password reset email");
    } catch (error: any) {
      message.error("An unexpected error occurred");
      console.error(error);
    }
  };

  return (
    <Modal footer={null} title="Reset your password" onCancel={hideModal} visible>
      <Form layout="vertical" onFinish={onFinish} initialValues={{ email: initialEmail }}>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Please provide a valid email" },
            { type: "email", message: "Please provide a valid email" },
          ]}
        >
          <Input prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" isSubmit>
            Reset my password
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

interface IInvitationProps {
  invitationId: string;
}

const Invitation: React.FC<IInvitationProps> = ({ invitationId }) => {
  const [streamDetails, setStreamDetails] = useState<StreamDetails | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStreamDetails = async (invitationId: string) => {
      try {
        let result = await GetInvitationStreamDetails(invitationId);
        if (result) {
          setStreamDetails(result);
        }
      } catch (error: any) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (invitationId) {
      fetchStreamDetails(invitationId);
    }
  }, [invitationId]);

  if (loading) {
    return <Loading />;
  }

  if (!streamDetails) {
    return null;
  }

  let orgImage = streamDetails.organisationImage ? <Avatar size={100} src={GetImage(streamDetails.organisationImage, ImageSize.medium)} /> : null;

  return (
    <div style={{ textAlign: "center" }}>
      <Title level={4} style={{ marginBottom: 5 }}>
        {streamDetails.organisationName}
      </Title>
      {orgImage}
      <Paragraph type="secondary" style={{ marginTop: "1rem" }}>
        Invites you to join their stream
      </Paragraph>
      <Title level={4}>{streamDetails.streamName}</Title>
      <Paragraph ellipsis={{ rows: 4, expandable: true }}>{streamDetails.streamDescription}</Paragraph>
    </div>
  );
};

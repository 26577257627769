import React, { Suspense, lazy, useEffect, useState } from "react";
import { Switch, Route, BrowserRouter, useLocation } from "react-router-dom";
import { AuthProvider } from "contexts/common/auth-context";
import PrivateRoute from "components/common/private-route";
import environment from "environment";
// import { analytics } from "./firebase";
import ScrollToTop from "components/common/scroll-to-top";
import { message } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

// Not lazy loading these right now as we want signin and signup to load quickly and toggle seamlessly
// Could improve this
import SignIn from "pages/account/signin";
import SignUp from "pages/account/signup";
import Join from "pages/join";
import { analytics } from "firebase";
import { logEvent, setCurrentScreen } from "firebase/analytics";
const Manage = lazy(() => import("pages/account/manage"));
const OrganiserRoutes = lazy(() => import("pages/organiser"));
const MemberRoutes = lazy(() => import("pages"));
const Impersonate = lazy(() => import("pages/admin/impersonate"));

// TODO add an error boundary
// TODO add better loading icon (though it needs to be different to the standard rippling loader)
function App() {
  return (
    <AuthProvider>
      <NetworkStatus />
      {!environment.isProduction && <DeveloperTools />}
      <BrowserRouter>
        <ScrollToTop />
        <PageViewTracker />
        <Suspense fallback={null}>
          <Switch>
            <Route path="/account/signin" component={SignIn} />
            <Route path="/account/signup" component={SignUp} />
            <Route path="/account/manage" component={Manage} />
            <Route path="/join/:organisationId/:streamId" component={Join} />
            <Route exact path="/404" component={() => <h1>You look lost</h1>} />
            <PrivateRoute path="/organiser" component={OrganiserRoutes} />
            <PrivateRoute exact path="/admin/impersonate/:userId" component={Impersonate} />
            <PrivateRoute path="*" component={MemberRoutes} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;

const NetworkStatus: React.FC = () => {
  useEffect(() => {
    window.addEventListener("online", () => {
      message.success({ content: "Reconnected", key: " network-state", duration: 2 });
    });

    window.addEventListener("offline", () => {
      message.error({ content: "No connection", key: " network-state", duration: 0 });
    });
  }, []);

  return null;
};

const DeveloperTools: React.FC = () => {
  const [show, setShow] = useState(true);
  const screen = useBreakpoint();

  if (!show) {
    return null;
  }

  let text = "sm";
  if (screen.xxl) {
    text = "xxl";
  } else if (screen.xl) {
    text = "xl";
  } else if (screen.lg) {
    text = "lg";
  } else if (screen.md) {
    text = "md";
  } else if (screen.sm) {
    text = "sm";
  } else if (screen.xs) {
    text = "xs";
  }

  return (
    <div
      onClick={() => setShow(false)}
      style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 1000, textAlign: "center", backgroundColor: "red" }}
    >
      {text}
    </div>
  );
};

const PageViewTracker: React.FC = () => {
  let location = useLocation();

  useEffect(() => {
    if (analytics) {
      let url = `${location.pathname}${location.search}${location.hash}`;
      setCurrentScreen(analytics, url);
      logEvent(analytics, "screen_view");
    }
  }, [location]);

  return null;
};

import React, { useState, useEffect } from "react";
import { Link, RouteComponentProps, Redirect } from "react-router-dom";
import useAuth from "contexts/common/auth-context";
import { Row, Col, Form, Alert, Input, Typography, Avatar } from "antd";
import Box from "containers/common/box";
import { MailOutlined, LockOutlined, GoogleOutlined, UserOutlined } from "@ant-design/icons";
import Loading from "components/common/loading";
import { GetInvitationStreamDetails, StreamDetails } from "services/member/functions/invitations";
import { GetImage } from "services/common/images";
import * as queryString from "query-string";
import { ImageSize } from "models/common/image";
import FullscreenPage from "containers/common/fullscreen-page";
import Button from "components/common/core/button";

const { Title, Paragraph } = Typography;

interface IRouteParams {
  r?: string;
}
const SignUp: React.FC<RouteComponentProps<IRouteParams>> = ({ location }) => {
  const auth = useAuth();

  const parseInvitationId = (redirectUrl: string | undefined): string | null => {
    if (redirectUrl) {
      if (redirectUrl.startsWith("/account/join")) {
        return (queryString.parse(redirectUrl.substring(redirectUrl.indexOf("?"))).i as string) || null;
      }
    }
    return null;
  };

  const redirect = queryString.parse(location.search).r as string;
  const invitationId = parseInvitationId(redirect);

  if (auth.isAuthenticated) {
    return <Redirect to={redirect ? decodeURIComponent(redirect) : "/"} />;
  }

  return (
    <FullscreenPage>
      <div style={{ padding: "1rem", width: "100%" }}>
        <div style={{ maxWidth: "300px", margin: "auto" }}>
          <img src="/images/logo-white.svg" alt="makeripples logo" style={{ width: "100%", padding: "1rem 2rem" }} />
        </div>
        <Row gutter={[12, 12]} justify="center">
          {invitationId && (
            <Col flex="0 1 300px">
              <Box>
                <Invitation invitationId={invitationId} />
              </Box>
            </Col>
          )}
          <Col flex="0 1 300px">
            <Box>
              <Title level={4} style={{ textAlign: "center" }}>
                Let's get started
              </Title>
              <SignUpForm continueUrl={redirect} />
              <p style={{ marginTop: "1rem", textAlign: "center" }}>
                Already have an account? <Link to={"/account/signin" + (redirect ? `?r=${redirect}` : "")}>Sign in</Link>
              </p>
            </Box>
          </Col>
        </Row>
      </div>
    </FullscreenPage>
  );
};

export default SignUp;

interface ISignUpFormProps {
  continueUrl?: string;
}

const SignUpForm: React.FC<ISignUpFormProps> = ({ continueUrl }) => {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [signUpError, setSignUpError] = useState(null);
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const isOrganiserSignup = continueUrl?.startsWith("/organiser") || false;
      await auth.actions.signUp(values.name, values.email, values.password, isOrganiserSignup);
    } catch (error: any) {
      setSignUpError(error.message);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Form form={form} layout="vertical" onFinish={onFinish} hideRequiredMark>
        {signUpError && (
          <Form.Item>
            <Alert message={signUpError} type="error" />
          </Form.Item>
        )}
        <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please provide your name" }]}>
          <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Please provide a valid email" },
            { type: "email", message: "Please provide a valid email" },
          ]}
        >
          <Input prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />} />
        </Form.Item>
        <Form.Item name="password" label="Password" rules={[{ required: true, message: "Please input your password" }]}>
          <Input.Password prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />} />
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button style={{ width: "100%", fontWeight: "bold" }} loading={loading} type="primary" isSubmit>
            Sign up
          </Button>
          <br />
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          <Button icon={<GoogleOutlined />} type="default" onClick={auth.actions.signInWithGoogle}>
            Sign up with Google
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

interface IInvitationProps {
  invitationId: string;
}

const Invitation: React.FC<IInvitationProps> = ({ invitationId }) => {
  const [streamDetails, setStreamDetails] = useState<StreamDetails | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStreamDetails = async (invitationId: string) => {
      try {
        let result = await GetInvitationStreamDetails(invitationId);
        if (result) {
          setStreamDetails(result);
        }
      } catch (error: any) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (invitationId) {
      fetchStreamDetails(invitationId);
    }
  }, [invitationId]);

  if (loading) {
    return <Loading />;
  }

  if (!streamDetails) {
    return null;
  }

  let orgImage = streamDetails.organisationImage ? <Avatar size={100} src={GetImage(streamDetails.organisationImage, ImageSize.medium)} /> : null;

  return (
    <div style={{ textAlign: "center" }}>
      <Title level={4} style={{ marginBottom: 5 }}>
        {streamDetails.organisationName}
      </Title>
      {orgImage}
      <Paragraph type="secondary" style={{ marginTop: "1rem" }}>
        Invites you to join their stream
      </Paragraph>
      <Title level={4}>{streamDetails.streamName}</Title>
      <Paragraph ellipsis={{ rows: 4, expandable: true }}>{streamDetails.streamDescription}</Paragraph>
    </div>
  );
};

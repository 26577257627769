import React from 'react';

interface IProps {
  style?: React.CSSProperties;
}

const Box: React.FC<IProps> = ({ children, style }) => {
  return <div style={{backgroundColor: 'white', borderRadius: 12, padding: '1rem', ...style}}>
    {children}
  </div>
}

export default Box;
import IFile from "./file";

export enum ImageSize { placeholder, thumbnail, small, medium, large, full };

export default interface IScaledImage extends IFile {
  sizes?: {
    placeholder?: string,
    thumbnail?: string,
    small? : string,
    medium?: string,
    large?: string
  }
}
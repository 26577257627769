import React, { createContext, useState, useContext, useEffect } from "react";
import { ImpersonateUser } from "services/admin/functions/impersonate";
import environment from "environment";
import { auth } from "firebase";
import { SendEmailVerification } from "services/common/functions/auth";
import {
  UserInfo,
  User as FirebaseUser,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithRedirect,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  updateProfile,
  updatePassword,
  reauthenticateWithPopup,
  EmailAuthProvider,
  signInWithCustomToken,
  reauthenticateWithCredential,
  applyActionCode,
  onAuthStateChanged,
} from "firebase/auth";

export interface Auth {
  isAuthenticated: boolean;
  user: User | null;
  actions: Actions;
}

export interface User {
  id: string;
  name: string;
  email: string;
  emailVerified: boolean;
  photoUrl?: string | null;
  providerData: (UserInfo | null)[];
  isAdmin: boolean;
}

export interface Actions {
  signIn(email: string, password: string): Promise<boolean>;
  signOut(): Promise<boolean>;
  signInWithGoogle(): void;
  resetPassword(email: string, continueUrl?: string): void;
  signUp(name: string, email: string, password: string, isOrganiserSignup: boolean): Promise<boolean>;
  update(name: string): Promise<void>;
  addPassword(password: string): Promise<void>;
  updatePassword(oldPassword: string, newPassword: string): Promise<void>;
  impersonateUser(userId: string): Promise<void>;
  verifyEmail(code: string): Promise<void>;
}

const defaultValue: Auth = {
  isAuthenticated: false,
  user: null,
  actions: {
    async signIn(email, password) {
      return false;
    },
    async signOut() {
      return false;
    },
    signInWithGoogle() {
      return;
    },
    resetPassword(email, continueUrl) {
      return;
    },
    async signUp(name, email, password, isOrganiserSignup) {
      return false;
    },
    async update(name) {
      return;
    },
    async addPassword(password) {
      return;
    },
    async updatePassword(oldPassword, newPassword) {
      return;
    },
    async impersonateUser(userId) {
      return;
    },
    async verifyEmail(code) {
      return;
    },
  },
};

const AuthContext = createContext<Auth>(defaultValue);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const authChanged = async (u: FirebaseUser | null) => {
    if (u) {
      let tokenResult = await u.getIdTokenResult();
      setUser({
        id: u.uid,
        name: u.displayName || "Unknown User",
        email: u.email || "Unknown Email",
        emailVerified: u.emailVerified,
        photoUrl: u.photoURL,
        providerData: u.providerData,
        isAdmin: !!tokenResult.claims.admin,
      });
    } else {
      setUser(null);
    }
    setLoading(false);
  };

  const actions: Actions = {
    async signIn(email, password) {
      await signInWithEmailAndPassword(auth, email, password);
      return true;
    },
    async signOut() {
      await signOut(auth);
      return true;
    },
    signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      signInWithRedirect(auth, provider);
    },
    resetPassword(email, continueUrl) {
      sendPasswordResetEmail(auth, email, continueUrl ? { url: continueUrl } : undefined);
    },
    async signUp(name, email, password, isOrganiserSignup) {
      let result = await createUserWithEmailAndPassword(auth, email, password);
      if (result && result.user) {
        await updateProfile(result.user, { displayName: name });
        authChanged(auth.currentUser);

        SendEmailVerification(isOrganiserSignup ? `${environment.baseUrl}/organiser` : "");
        return true;
      }
      return false;
    },
    async update(name) {
      if (!auth.currentUser) {
        return;
      }

      await updateProfile(auth.currentUser, { displayName: name });
      authChanged(auth.currentUser);
    },
    async addPassword(password) {
      if (!auth.currentUser) {
        return;
      }

      try {
        await updatePassword(auth.currentUser, password);
      } catch (error: any) {
        if (error.message === "auth/requires-recent-login") {
          // Reauthenticate and try again
          let result = await reauthenticateWithPopup(auth.currentUser, new GoogleAuthProvider());
          if (result?.user) {
            this.addPassword(password);
          } else {
            throw new Error("Unable to authenticate and update your password");
          }
        } else {
          // Sad face....
          throw error;
        }
      }
      authChanged(auth.currentUser);
    },
    async updatePassword(oldPassword, newPassword) {
      if (!auth.currentUser) {
        return;
      }

      const user = auth.currentUser;
      let credentials = EmailAuthProvider.credential(user?.email || "", oldPassword);
      await reauthenticateWithCredential(user, credentials);
      await updatePassword(user, newPassword);
    },
    async impersonateUser(userId) {
      if (user?.isAdmin) {
        // Retrieve impersonation token
        const token = await ImpersonateUser(userId);
        if (token) {
          signInWithCustomToken(auth, token);
        } else {
          throw new Error("Impersonation failed");
        }
      } else {
        throw new Error("Permission denied");
      }
    },
    async verifyEmail(code) {
      await applyActionCode(auth, code);
      authChanged(auth.currentUser);
    },
  };

  useEffect(() => {
    onAuthStateChanged(auth, (u: FirebaseUser | null) => authChanged(u));
  }, []);

  const authObj: Auth = { isAuthenticated: !!user, user, actions };

  return <AuthContext.Provider value={authObj}>{loading ? null : children}</AuthContext.Provider>;
};

export default function useAuth() {
  const context = useContext<Auth>(AuthContext);

  return context;
}

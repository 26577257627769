import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import useAuth from 'contexts/common/auth-context';

const PrivateRoute: React.FC<RouteProps> = ({ location, children, ...rest}) => {
  const auth = useAuth();

  // We're all logged in so good to proceed
  if (auth.isAuthenticated) {
    return <Route { ...rest }/>
  }
  
  // Not logged in so we need to work out where to redirect to
  let redirectUrl = '/account/signin';
  let currentUrl = `${location?.pathname}${location?.search}${location?.hash}`;
  
  if (currentUrl && currentUrl !== '/') {
    if (currentUrl.startsWith('/account/join')) {
      redirectUrl = '/account/signup';
    }
      
    redirectUrl += `?r=${encodeURIComponent(currentUrl)}`;
  }

  return <Redirect to={redirectUrl} />
}

export default PrivateRoute;
import { useEffect, useRef } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

function ScrollToTop({ history, location }: RouteComponentProps) {
  let locationRef = useRef(location); // Only works as it shouldn't really ever re-render

  useEffect(() => {
    const unlisten = history.listen((newLocation) => {
      // Grab the value out of the location ref and set as the oldLocation, then update the location ref.
      const oldLocation = locationRef.current;
      locationRef.current = newLocation;

      if ((newLocation.state as any)?.preventScroll) {
        return;
      }

      // If the path hasn't changed, or we're popping from history (i.e back or forward)
      // then there's nothing to do
      if (oldLocation.pathname === newLocation.pathname) {
        return;
      }
      if (history.action === "POP") {
        return;
      }

      // Otherwise it's a new page so we should scroll to the top!
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default withRouter(ScrollToTop);

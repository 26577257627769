import IScaledImage, { ImageSize } from "models/common/image";

export function GetImage(image: IScaledImage | string | undefined | null, minimumSize: ImageSize): string | undefined {
  if (!image) { return undefined; }
  if (typeof image === 'string') { return image; }
  if (!image.sizes || minimumSize === ImageSize.full) {
    return image.url;
  }

  switch(minimumSize) {
    case ImageSize.placeholder:
      return image.sizes.placeholder || image.sizes.thumbnail || image.sizes.small || image.sizes.medium || image.sizes.large || image.url;

    case ImageSize.thumbnail:
      return image.sizes.thumbnail || image.sizes.small || image.sizes.medium || image.sizes.large || image.url;

    case ImageSize.small:
      return image.sizes.small || image.sizes.medium || image.sizes.large || image.url;

    case ImageSize.medium:
      return image.sizes.medium || image.sizes.large || image.url;

    case ImageSize.large:
      return image.sizes.large || image.url;
  }
}
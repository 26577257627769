import { functions } from "firebase";
import { httpsCallable } from "firebase/functions";

export async function ImpersonateUser(userId: string): Promise<string | null> {
  var func = httpsCallable<{ userId: string }, { success: boolean; token: string }>(functions, "admin_impersonateUser");

  const response = await func({ userId });

  if (response.data.success) {
    return response.data.token;
  }

  return null;
}

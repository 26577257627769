import IEnvironment from "./interface";

const environment: IEnvironment = {
  baseUrl: 'https://app.makeripples.nz',
  isProduction: true,
  firebaseConfig: {
    apiKey: "AIzaSyBK_nyPHaWp_SC-BfCkbtPrzllHslYiUpg",
    authDomain: "app.makeripples.nz",
    databaseURL: "https://makeripples-3861f.firebaseio.com",
    projectId: "makeripples-3861f",
    storageBucket: "makeripples-3861f.appspot.com",
    messagingSenderId: "457786779283",
    appId: "1:457786779283:web:c9364028af97feb0c68f66",
    measurementId: "G-W3E5DPWSL5"
  }
}

export default environment;
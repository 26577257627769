import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "components/common/loading";
import { message, Row, Col, Avatar, Typography, Layout, Form, Alert, Input, Divider, Modal } from "antd";
import { GetImage } from "services/common/images";
import { ImageSize } from "models/common/image";
import Box from "containers/common/box";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import { Store } from "antd/lib/form/interface";
import { SendInvitationToSelf, GetStreamDetailsForSelfInvitation, StreamDetails } from "services/member/functions/invitations";
import FullscreenPage from "containers/common/fullscreen-page";
import Button from "components/common/core/button";

const { Title, Text, Paragraph } = Typography;

const Join: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [streamDetails, setStreamDetails] = useState<StreamDetails | null>(null);
  const { organisationId, streamId } = useParams<{ organisationId: string; streamId: string }>();
  const [showAbout, setShowAbout] = useState(false);

  useEffect(() => {
    const getData = async () => {
      // Load the relevant information
      setStreamDetails(await GetStreamDetailsForSelfInvitation(organisationId, streamId));
      setLoading(false);
    };

    getData();
  }, [organisationId, streamId]);

  // Wait till everything is loaded
  if (loading) {
    return <Loading />;
  }

  // Doesn't appear this invitation is valid: does not exist, is disabled, or is a single invite and has been accepted.
  if (!streamDetails) {
    return (
      <Layout>
        <Row style={{ minHeight: "100vh" }} align="middle" justify="center">
          <Box style={{ width: "100%", maxWidth: 400, margin: 12, position: "relative", overflow: "hidden", textAlign: "center" }}>
            <Col xs={24}>
              <Title level={2}>Sorry!</Title>
              <Paragraph>this stream is not currently accepting sign ups</Paragraph>
              <a href="https://makeripples.nz">Return home</a>
            </Col>
          </Box>
        </Row>
      </Layout>
    );
  }

  const coverImage = GetImage(streamDetails.streamImage, ImageSize.small);

  return (
    <FullscreenPage>
      <Row style={{ minHeight: "100vh" }} align="middle" justify="center">
        <Box style={{ width: "100%", maxWidth: 400, margin: 12, position: "relative", textAlign: "center" }}>
          {coverImage && (
            <div
              style={{
                overflow: "hidden",
                height: 0,
                backgroundImage: `url("${coverImage}")`,
                backgroundSize: "cover",
                paddingBottom: "33%",
                margin: "-1rem",
              }}
            />
          )}
          <Col xs={24}>
            {streamDetails.organisationImage && (
              <Avatar
                size={120}
                src={GetImage(streamDetails.organisationImage, ImageSize.medium)}
                style={{ top: -50, marginBottom: -30, boxShadow: "0px 5px 10px 0px rgba(0,0,0,0.2)", backgroundColor: "white" }}
              />
            )}
            <div>A stream by {streamDetails.organisationName}</div>
            <Title level={3}>{streamDetails.streamName}</Title>
            <Button style={{ marginTop: "0.5rem" }} onClick={() => setShowAbout(true)}>
              About this stream
            </Button>
          </Col>
          <Divider />
          <Col xs={24}>
            <JoinForm organisationId={organisationId} streamId={streamId} />
          </Col>
        </Box>
      </Row>
      <Modal visible={showAbout} onCancel={() => setShowAbout(false)} footer={null}>
        <Title level={4}>About this stream</Title>
        <Text style={{ whiteSpace: "pre-wrap" }}>{streamDetails.streamDescription}</Text>
      </Modal>
    </FullscreenPage>
  );
};

export default Join;

const JoinForm: React.FC<{ organisationId: string; streamId: string }> = ({ organisationId, streamId }) => {
  const [form] = Form.useForm();
  const [joinError, setJoinError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: Store) => {
    setJoinError(null);
    setLoading(true);
    try {
      let result = await SendInvitationToSelf(organisationId, streamId, values.email, values.name);
      if (result.success) {
        form.resetFields();
        message.success(`Thanks! We've sent an invitation email to ${values.email}`);
      } else {
        if (result.message) {
          setJoinError(result.message);
        } else {
          setJoinError("Sorry, something went wrong. Please try again");
        }
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setJoinError("Sorry, something went wrong. Please try again");
      setLoading(false);
    }
  };

  return (
    <div>
      <Paragraph>Send yourself an invite to join this stream</Paragraph>
      <Form form={form} onFinish={onFinish} layout="vertical" autoComplete="off">
        {joinError && (
          <Form.Item>
            <Alert message={joinError} type="error" />
          </Form.Item>
        )}
        <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please provide your name" }]} style={{ textAlign: "left" }}>
          <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Please provide a valid email" },
            { type: "email", message: "Please provide a valid email" },
          ]}
          style={{ textAlign: "left" }}
        >
          <Input prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />} />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} isSubmit type="primary">
            Send invite
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

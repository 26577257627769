import environment from "environment";
import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const app = initializeApp(environment.firebaseConfig);
const db = initializeFirestore(app, { ignoreUndefinedProperties: true });
const analytics = getAnalytics(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const auth = getAuth(app);

export { app, db, storage, functions, auth, analytics };

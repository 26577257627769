import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import environment from 'environment';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();


Sentry.init({
  dsn: "https://1ac9402cf3c24b62b7c994a0c0c8f8ac@o349798.ingest.sentry.io/5455128",
  integrations: [
    new Integrations.BrowserTracing({routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)}),
  ],
  tracesSampleRate: 1.0,
  release: process.env.npm_package_version,
  environment: environment.firebaseConfig.projectId
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

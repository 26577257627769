import React from 'react';
import styled, { keyframes } from 'styled-components';

export interface ILoadingProps {
  overlay?: boolean;
}

const Loading: React.FC<ILoadingProps> = ({ overlay = false }) => {
  if (overlay) {
    return <div style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, backgroundColor: 'rgba(255, 255, 255,  0.5)', zIndex: 1000, textAlign: 'center'}}>
      <RippleLoader />
    </div>
  } else {
    return <div style={{position: 'relative', textAlign: 'center', zIndex: 1000}}>
      <RippleLoader />
    </div>
  }
}

export default Loading;


const Wrapper = styled.div`
  display: inline-block;
  margin: auto;
  padding: 5rem;
  position: relative;
`;

const animation = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

interface RippleProps {
  left?: number;
  top?: number;
  delay?: string;
}

const Ripple = styled.div<RippleProps>`
  border-radius: 50%;
  border: 3px solid ${props => props.color || 'blue'};
  animation: ${animation} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation-delay: ${props => props.delay || '0s'};
  position: absolute;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  width: 40px;
  height: 40px;
  margin-left: ${props => -20 + (props.left || 0)}px;
  margin-top: ${props => -20 + (props.top || 0)}px;
  opacity: 0;
`;

function RippleLoader() {
  return <Wrapper>
    <Ripple color="#B07FB1" />
    <Ripple color="#D8B6D2" left={-16} top={-13} delay="0.6s"  />
    <Ripple color="#E9D0E2" left={9} top={23} delay="1.3s" />
  </Wrapper>
}
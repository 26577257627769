import { SelectQuestion } from "components/common/form/form-select";
import { functions } from "firebase";
import { httpsCallable } from "firebase/functions";
import IScaledImage from "models/common/image";

export type StreamDetails = {
  organisationId: string;
  streamId: string;
  organisationName: string;
  streamName: string;
  streamDescription: string;
  organisationImage: IScaledImage | undefined;
  streamImage: IScaledImage | undefined;
  memberInsightsQuestions: SelectQuestion[];
};

export async function GetInvitationStreamDetails(invitationId: string): Promise<StreamDetails> {
  var func = httpsCallable<{ invitationId: string }, StreamDetails>(functions, "getInvitationStreamDetails");

  return await (
    await func({ invitationId })
  ).data;
}

export async function AcceptInvitation(invitationId: string): Promise<{ success: boolean }> {
  const func = httpsCallable<{ invitationId: string }, { success: boolean }>(functions, "acceptInvitation");
  return (await func({ invitationId })).data;
}

export async function GetStreamDetailsForSelfInvitation(organisationId: string, streamId: string): Promise<StreamDetails | null> {
  const func = httpsCallable<{ organisationId: string; streamId: string }, { success: boolean; data: StreamDetails }>(
    functions,
    "getStreamDetailsForSelfInvitation"
  );
  const result = await (await func({ organisationId, streamId })).data;
  if (result.success) {
    return result.data;
  }

  return null;
}

export async function SendInvitationToSelf(
  organisationId: string,
  streamId: string,
  email: string,
  name: string
): Promise<{ success: boolean; message?: string }> {
  const func = httpsCallable<{ organisationId: string; streamId: string; email: string; name: string }, { success: boolean; message?: string }>(
    functions,
    "sendInvitationToSelf"
  );
  return await (
    await func({ organisationId, streamId, email, name })
  ).data;
}

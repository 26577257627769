import { Grid } from 'antd';
import React from 'react';
import styled from 'styled-components';
import 'styles/fullscreen.less'

const Container = styled.div<{small: boolean}>`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: url('/images/fish.png') left top no-repeat, url('/images/whale.png') right bottom no-repeat, linear-gradient(180deg, #6153D7 0%, #4B4B84 100%);
  background-size: ${props => props.small ? '300px, 400px' : '40%, 60%' };
`;

const FullscreenPage: React.FC = ({ children }) => {
  const screens = Grid.useBreakpoint();

  return <div style={{height: '100%', backgroundColor: '#4B4B84'}}>
    <Container small={screens.sm || false}>{children}</Container>
  </div>
}

export default FullscreenPage;
import React, { CSSProperties } from 'react';
import { Button as AntdButton } from 'antd';

export interface IButtonProps {
  size?: "small" | "middle" | "large",
  loading?: boolean,
  type?: "default" | "primary" | "text" | "link" | "ghost" | "dashed"
  isSubmit?: boolean,
  isDisabled?: boolean,
  icon?: React.ReactNode,
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  style?: CSSProperties,
  href?: string,
  isDanger?: boolean,
  isGhost?: boolean,
  children?: React.ReactNode,
  isSquare?: boolean
}

const Button: React.FC<IButtonProps> = ({ icon, size = "middle", loading = false, type = "default", isSubmit, isDisabled = false, onClick, style, children, href, isGhost = false, isDanger = false, isSquare = false }) => {
  if (!children && !icon) { throw Error('Button must be provided with children or an icon'); }

  return <AntdButton 
    shape={isSquare ? undefined : (!children ? 'circle' : 'round')} 
    size={size}
    loading={loading}
    onClick={onClick}
    type={type}
    htmlType={isSubmit ? 'submit' : undefined}
    disabled={isDisabled}
    icon={icon}
    style={style}
    href={href}
    ghost={isGhost}
    danger={isDanger}
    target={href ? '_blank' : undefined}
  >
    {children}
  </AntdButton>
}

export default Button;
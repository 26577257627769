import environment from "environment";
import { functions } from "firebase";
import { httpsCallable } from "firebase/functions";

// TODO Add proper error handling...
export async function SendEmailVerification(continueUrlAbsolute: string | null): Promise<void> {
  var func = httpsCallable<{ continueUrl: string }>(functions, "sendVerificationEmail");
  await func({ continueUrl: continueUrlAbsolute || environment.baseUrl });

  return Promise.resolve();
}
